<template>
  <section>
    <div class="card card-body">
      <app-preloader v-if="!dataLoaded" style="position: relative; top:200px; z-index:100"></app-preloader>
      <div class="row">
        <div class="col-md-5">
          <h4>{{ contentBlock.id }}. {{ contentBlock.title }}</h4>
        </div>
        <div class="col-md-3">
          <div class="hp-arrows">
            <i class="fa fa-chevron-left"
               @click="timelineBackwards()"
               :title="$t('hp.backwards')"
               data-test="hp_timeline_backwards"
            >
            </i>
            <i class="fa fa-chevron-right"
               @click="timelineForwards()"
               :title="$t('hp.forwards')"
               data-test="hp_timeline_forwards"
            >
            </i>
          </div>
        </div>
        <div class="col-md-2 text-right">
          <router-link
            v-if="showContentBlockInfoButton"
            tag="a"
            class="btn btn-secondary"
            title="Content block settings"
            :to="{ name: 'contentBlock_edit', params: { id: contentBlock.id }}"
            target="_blank"
          >
            <span class="fas fa-info-circle"></span>
          </router-link>
          <button class="btn btn-secondary" @click="getContentBlockItems" data-test="hp_timeline_refresh">
            <i class="fas fa-sync-alt"></i> {{ $t('hp.refresh') }}
          </button>
        </div>
        <div class="col-md-2">
          <app-select
            v-model="boxTimeLenght"
            :options="boxTimeLengths"
            no-empty-value
            no-label
            disable-form-group
            @change="changeBoxTimeLength"
            id="hp_timeline_boxTimeLength"
          >
          </app-select>
        </div>
      </div>

      <div class="hp-timeline">
        <div class="hp-time-status" :style="hpTimeStatusPosition">
          <span>{{ $t('hp.now') }}</span>
        </div>
        <div class="hp-timeline-header">
          <div class="hp-header-first"></div>
          <div v-for="(time, index) in timelineConfig.times" :key="`item-${time + index}`">
            {{ time | prettyTime }}
            <strong v-if="index % 2" style="margin-left:20px; color:#787878;"><small>{{
                time | prettyDate
              }}</small></strong>
          </div>
        </div>
        <template v-for="(position, index) in timelineConfig.positions">
          <div
            class="hp-timeline-row"
            :key="`item-${index}`"
            v-if="contentBlock.numberOfItems >= position && isDisabledPosition(position) === false"
          >
            <div class="left-panel">
              {{ $t('hp.position') }} #{{ position }}
              <button
                class="btn btn-success"
                @click="showCreateModal(position, timelineConfig.times[0])"
                data-test="hp_timeline_add"
              >
                {{ $t('hp.add_article') }}
              </button>
              <div v-if="positionCtrs[position - 1].exists" class="ctr">
                CTR:
                {{ positionCtrs[position - 1].value * 100 | formatNumber }} %
                <app-tooltip :title="$t('hp.ctr_info')"></app-tooltip>
              </div>
            </div>
            <div class="hp-box" v-for="(time, index) in timelineConfig.times" :key="`item-${time + index}`">
              <a
                class="hp-box-add"
                :class="{'hp-box-add-half': copiedItem.id}"
                :title="$t('hp.add_article')"
                @click="showCreateModal(position, time)"
                data-test="hp_timeline_add"
              >
                <i class="fa fa-plus-circle"></i>
              </a>
              <a
                v-if="copiedItem.id"
                class="hp-box-paste"
                :title="$t('hp.insert_article')"
                @click="paste(position, time)"
                data-test="hp_timeline_paste"
              >
                <i class="fa fa-paste"></i>
              </a>
            </div>
            <template v-for="(item, index) in contentBlockItems">
              <div
                :key="`item-${item + index}`"
                v-if="item.position === position"
                class="hp-box-article"
                :class="getClassNameBySite(item)"
                :style="setArticleBoxPosition(item)"
              >
                <div class="article-info" :title="item.title">
                  <h6>
                    <span v-if="!item.alternativeTitle">{{ item.title }}</span>
                    <template v-if="item.alternativeTitle">
                      <span>{{ item.alternativeTitle }}</span>
                    </template>
                    <span
                      v-if="item.type === 'external'"
                      class="label label-info"
                      style="padding:0px 5px;"
                    >
                                        EXT
                                    </span>
                  </h6>
                  <span class="time" :title="item.publishedSince | prettyDateTime">
                                    {{ item.publishedSince | prettyTime }} - {{ item.publishedUntil | prettyTime }}
                                </span>
                  <span v-if="item.copied">
                                    <i class="fa fa-copy"></i>
                                </span>
                  <div>
                    {{ $t('feUser.created_by') }}: {{ getUserNameById(item.createdBy) }}
                  </div>
                  <div v-if="item.modifiedBy === null">&nbsp;</div>
                  <div v-if="item.modifiedBy !== null">
                    {{ $t('feUser.modified_by') }}: {{ getUserNameById(item.modifiedBy) }}
                  </div>
                  <span v-if="item.videoEmbedCount > 0" class="fa fa-video video-embed"></span>
                  <span v-if="item.fbShared" class="fab fa-facebook fb-shared"
                        :title="$t('hpTopicOffer.fb_shared')"></span>
                  <div class="ctr font-bold" v-if="contentBlock.id === 1 && ctrExists(item)">
                    CTR: {{ getCtr(item).value * 100 | formatNumber }} %
                    <em class="fa" :class="getStatusIcon(getCtr(item).status)" aria-hidden="true"></em>
                  </div>
                </div>
                <div class="icons">
                  <button
                    class="btn btn-default btn-xs"
                    @click="showEditModal(item)"
                    :title="$t('buttons.edit')"
                    data-test="hp_item_edit"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-default btn-xs"
                    @click="showInfoModal(item)"
                    :title="$t('buttons.info')"
                    data-test="hp_item_info"
                  >
                    <i class="fa fa-info"></i>
                  </button>
                  <button
                    class="btn btn-default btn-xs"
                    @click="copy(item)"
                    :title="$t('hp.copy_article')"
                    data-test="hp_item_copy"
                  >
                    <i class="fa fa-copy"></i>
                  </button>
                  <button
                    class="btn btn-default btn-xs"
                    @click="showDeleteModal(item)"
                    :title="$t('buttons.delete_button')"
                    data-test="hp_item_delete"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <app-create-modal
      v-if="createModal"
      :content-block-id="contentBlock.id"
      :position="selectedPosition"
      :publishedSince="selectedPublishedSince"
      :publishedUntil="selectedPublishedUntil"
      @change="getContentBlockItems"
      @close="closeCreateModal"
    >
    </app-create-modal>
    <app-edit-modal
      v-if="editModal"
      :content-block-id="contentBlock.id"
      :content-block-item="selectedContentBlockItem"
      @change="getContentBlockItems"
      @close="closeEditModal"
    >
    </app-edit-modal>
    <app-delete-modal
      v-if="deleteModal"
      :content-block-item="selectedContentBlockItem"
      @post-delete="getContentBlockItems"
      @close="closeDeleteModal"
    >
    </app-delete-modal>
    <app-info-modal
      v-if="infoModal"
      :content-block-item="selectedContentBlockItem"
      @close="closeInfoModal"
    >
    </app-info-modal>
  </section>
</template>

<script>
import Preloader from '../../preloader/Preloader'
import CoreApi from '../../../api/core'
import DonApi from '../../../api/don'
import Moment from 'moment'
import Select from '../../form/select/Select'
import CreateModal from '../CreateModal'
import EditModal from '../EditModal'
import DeleteModal from '../DeleteModal'
import InfoModal from '../InfoModal'
import NotifyService from '../../../services/NotifyService'
import MediaService from '../../../services/media/MediaService'
import UserService from '../../../services/user/UserService'
import Tooltip from '../../tooltip/Tooltip'

/* Box width in pixels */
const BOX_WIDTH_PX = 150
const BOX_LEFT_OFFSET = 150
const BOX_RIGHT_MARGIN = 1
const BOX_COUNT = 11
const POSITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

export default {
  name: 'TimelineOld',
  props: {
    contentBlock: {
      type: Object
    }
  },
  components: {
    appPreloader: Preloader,
    appSelect: Select,
    appCreateModal: CreateModal,
    appEditModal: EditModal,
    appDeleteModal: DeleteModal,
    appInfoModal: InfoModal,
    appTooltip: Tooltip
  },
  data () {
    return {
      dataLoaded: false,
      timelineConfig: {
        positions: POSITIONS,
        times: [],
        ctrs: Array.from(Array(this.contentBlock.numberOfItems || 0)).map((i, idx) => ({
          id: idx + 1,
          value: 0,
          oldValue: 0,
          status: 0,
          exists: false,
          articleId: null
        }))
      },
      positionCtrs: Array.from(Array(this.contentBlock.numberOfItems || 0)).map((i, idx) => ({
        id: idx + 1,
        value: 0,
        exists: false
      })),
      hpTimeStatusPosition: 'left: 0px',
      hpTimeStatusInterval: 0,
      contentBlockItems: [],
      createModal: false,
      editModal: false,
      deleteModal: false,
      infoModal: false,
      selectedContentBlockItem: {},
      selectedPosition: 1,
      selectedPublishedSince: '',
      selectedPublishedUntil: '',
      boxTimeLenght: 1800,
      boxTimeLengths: [
        {
          id: 900,
          title: this.$t('hp.interval_1')
        },
        {
          id: 1800,
          title: this.$t('hp.interval_2')
        },
        {
          id: 3600,
          title: this.$t('hp.interval_3')
        },
        {
          id: 14400,
          title: this.$t('hp.interval_4')
        }
      ],
      timelinePosition: 0,
      timelinePositionCount: 0,
      copiedItem: {}
    }
  },
  computed: {
    showContentBlockInfoButton () {
      if (this.$store.getters['user/hasRole']('ROLE_SUPER_ADMIN')) {
        return true
      }
      return false
    }
  },
  methods: {
    init () {
      this.getContentBlockItems()
      this.getTimes()
    },
    getContentBlockItems () {
      this.dataLoaded = false
      const dateFrom = this.getStartTime().add(-1, 'days').toISOString()
      const dateTo = this.getEndTime().add(1, 'days').toISOString()
      const url = '/ContentBlockItemWithin?contentBlockId=' + this.contentBlock.id +
        '&startDatetime=' + dateFrom +
        '&endDatetime=' + dateTo
      CoreApi().get(url)
        .then(response => {
          this.contentBlockItems = response.data.data
          this.dataLoaded = true
          this.setHpTimeStatusPosition()
          this.setRealtimeCTRs()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getStartTime () {
      const now = Moment()
      now.add(-now.minutes(), 'm')
      now.add(this.timelinePosition, 's')
      now.set({ second: 0, millisecond: 0 })
      return now
    },
    getEndTime () {
      const endTime = this.getStartTime()
      endTime.add(
        (this.boxTimeLenght / 60) * BOX_COUNT,
        'm'
      )
      return endTime
    },
    getTimes () {
      this.boxTimeLenght = parseInt(this.boxTimeLenght)
      const startTime = this.getStartTime()
      const times = []
      for (let i = 1; i <= BOX_COUNT; i++) {
        times.push(startTime.toISOString())
        startTime.add(this.boxTimeLenght / 60, 'm')
      }
      this.timelineConfig.times = times
    },
    changeBoxTimeLength () {
      this.init()
    },
    getSiteLogo (externalSiteName) {
      return '/img/sites_logo/' + externalSiteName + '.png'
    },
    setArticleBoxPosition (item) {
      const width = this.getArticleBoxWidth(item)
      let style = 'left:' + this.getArticleBoxLeftValue(item) + 'px; width:' + width + 'px;'
      if (width === 0) {
        style = style + 'display:none;'
      }
      return style
    },
    getArticleBoxWidth (item) {
      const startTime = this.getStartTime()
      const publishedSince = Moment(item.publishedSince)
      const publishedUntil = Moment(item.publishedUntil)
      if (publishedUntil.diff(startTime) < 1) {
        return 0
      }
      let diffSeconds = publishedUntil.diff(publishedSince)
      if (publishedSince.diff(startTime) < 1) {
        diffSeconds = publishedUntil.diff(startTime)
      }
      return (diffSeconds / (this.boxTimeLenght * 1000)) * BOX_WIDTH_PX - BOX_RIGHT_MARGIN
    },
    getArticleBoxLeftValue (item) {
      const publishedSince = Moment(item.publishedSince)
      const diffSeconds = publishedSince.diff(this.getStartTime())
      const left = (diffSeconds / (this.boxTimeLenght * 1000)) * BOX_WIDTH_PX + BOX_LEFT_OFFSET
      if (BOX_LEFT_OFFSET > left) {
        return BOX_LEFT_OFFSET
      }
      return left
    },
    setHpTimeStatusPosition () {
      const diffSeconds = Moment().diff(this.getStartTime())
      const left = (diffSeconds / (this.boxTimeLenght * 1000)) * BOX_WIDTH_PX + BOX_LEFT_OFFSET
      this.hpTimeStatusPosition = 'left:' + left + 'px'
    },
    showCreateModal (position, publishedSince) {
      this.selectedPublishedSince = publishedSince
      let timeLength = this.boxTimeLenght
      if (timeLength <= 3600) {
        timeLength = 3600
      }
      this.selectedPublishedUntil = Moment(publishedSince).add(timeLength, 's').toISOString()
      this.selectedPosition = position
      this.createModal = true
    },
    closeCreateModal () {
      this.createModal = false
    },
    showEditModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.editModal = true
    },
    closeEditModal (contentBlockItemId) {
      this.editModal = false
    },
    showDeleteModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    showInfoModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.infoModal = true
    },
    closeInfoModal () {
      this.infoModal = false
    },
    timelineForwards () {
      this.timelinePositionCount++
      this.timelinePosition = this.timelinePosition + this.boxTimeLenght
      this.getTimes()
      if (this.timelinePositionCount === 7) {
        this.timelinePositionCount = 0
        this.getContentBlockItems()
      }
      this.setHpTimeStatusPosition()
    },
    timelineBackwards () {
      this.timelinePositionCount--
      this.timelinePosition = this.timelinePosition - this.boxTimeLenght
      this.getTimes()
      if (this.timelinePositionCount === -7) {
        this.timelinePositionCount = 0
        this.getContentBlockItems()
      }
      this.setHpTimeStatusPosition()
    },
    copy (item) {
      this.copiedItem = this._.cloneDeep(item)
      NotifyService.setSuccessMessage('Hp block ' + item.title + ' was copied.')
    },
    paste (position, publishedSince) {
      let timeLength = this.boxTimeLenght
      if (timeLength < 3600) {
        timeLength = 3600
      }
      this.copiedItem.position = position
      this.copiedItem.publishedSince = publishedSince
      this.copiedItem.publishedUntil = Moment(publishedSince).add(timeLength, 's').toISOString()
      if (this.copiedItem.imageInfo) {
        MediaService.createMedia(this.copiedItem.imageInfo)
          .then(media => {
            this.copiedItem.image = media.id
            this.copiedItem.imageInfo = media
            this.save(this.copiedItem)
          })
      } else {
        this.save(this.copiedItem)
      }
    },
    addOneSecondToPublishedSince (publishedSince) {
      const date = Moment(publishedSince)
      date.add(1, 's')
      return date.toISOString()
    },
    prepareRequest (item) {
      if (item.publishedSince) {
        item.publishedSince = this.addOneSecondToPublishedSince(item.publishedSince)
      }
      return item
    },
    async save (item) {
      this.$store.dispatch('contentBlockItem/create', this.prepareRequest(item))
        .then(() => {
          if (this.$store.getters['contentBlockItem/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.getContentBlockItems()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
          }
        })
        .catch(error => console.log(error))
    },
    isDisabledPosition (position) {
      const disabledPositions = this.contentBlock.disabledPositions.split(',')
      if (disabledPositions.includes(position.toString())) {
        return true
      }
      return false
    },
    getClassNameBySite (item) {
      let siteName = item.externalSiteName.toLowerCase()
      siteName = siteName
        .replace(' ', '-')
        .replace('ľ', 'l')
        .replace('š', 's')
        .replace('č', 'c')
        .replace('ť', 't')
        .replace('ž', 'z')
        .replace('ý', 'y')
        .replace('á', 'a')
        .replace('í', 'i')
        .replace('é', 'e')
        .replace('ú', 'u')
        .replace('ä', 'a')
        .replace('ň', 'n')
        .replace('ô', 'o')
        .replace('ľ', 'l')

      return 'hp-box-' + siteName
    },
    getUserNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    async setRealtimeCTRs () {
      if (process.env.NODE_ENV === 'development') {
        return
      }
      const now = Date.now()
      const contentBlockItems = this.contentBlockItems.filter(
        (item) => now >= new Date(item.publishedSince).getTime() && now <= new Date(item.publishedUntil).getTime()
      )
      if (contentBlockItems.length > 0) {
        const urlPositions = contentBlockItems.map((item) => 'position' + item.position + ':' + item.articleDocumentId).join('|')
        try {
          const response = await DonApi().get('/realTimeBoxCtr?brand=pluska&box=topbox&positions=' + encodeURI(urlPositions))
          response.data.ctrs.forEach((item) => {
            const ctr = this.timelineConfig.ctrs.find((ctr) => ctr.id === item.position)
            ctr.oldValue = ctr.value
            ctr.value = item.ctr
            ctr.status = ctr.value - ctr.oldValue
            ctr.status /= Math.abs(ctr.status) || 1
            ctr.exists = item.exists
            ctr.articleId = item.articleId
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    getStatusIcon (status) {
      return ['fa-arrow-down', 'fa-dot-circle', 'fa-arrow-up'][status + 1]
    },
    getCtr (item) {
      const ctr = this.timelineConfig.ctrs.find(
        (ctr) => ctr.exists && ctr.id === item.position && ctr.articleId === item.articleDocumentId
      )
      if (ctr !== null) {
        return ctr
      }
      return {
        value: 0,
        status: 0
      }
    },
    ctrExists (item) {
      return this.timelineConfig.ctrs.some(
        (ctr) => ctr.exists && ctr.id === item.position && ctr.articleId === item.articleDocumentId
      )
    }
  },
  created () {
    this.init()
  },
  mounted () {
    if (this.contentBlock.id === 1) {
      clearInterval(this.hpTimeStatusInterval)
      this.hpTimeStatusInterval = setInterval(() => {
        this.setHpTimeStatusPosition()
        this.setRealtimeCTRs()
      }, 10000)
      if (process.env.NODE_ENV !== 'development') {
        const positionsUrl = this.positionCtrs.map((ctr) => 'position' + ctr.id).join('|')
        DonApi().get('/historicalBoxCtr?brand=pluska&box=topbox&positions=' + encodeURI(positionsUrl)).then((res) => {
          res.data.ctrs.forEach((ctr) => {
            const selectedCtr = this.positionCtrs.find((item) => item.id === ctr.position)
            selectedCtr.exists = ctr.exists
            selectedCtr.value = ctr.ctr
          })
        })
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.hpTimeStatusInterval)
  }
}
</script>

<style lang="scss" scoped>

.hp-timeline {
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;

  .hp-timeline-header,
  .hp-timeline-row {
    width: 1800px;
    display: flex;
    position: relative;
  }

  .hp-timeline-header {
    overflow: hidden;

    div {
      color: #36474f;
      font-size: 14px;
      width: 150px;
      border-left: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 0 0 6px 8px;
    }

    div.hp-header-first {
      border-left: 0;
    }
  }

  .hp-box {
    border: 1px solid #e7e7e7;
    border-left: 0;
    border-top: 0;
    padding: 1px 0;
    width: 150px;
    position: relative;
    background: #f7f9fa;
    cursor: pointer;

    .hp-box-add {
      background-color: #f3f5f7;
      border-radius: 0px;
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
      text-align: center;
      display: none;
      justify-content: center;
      align-items: center;

      i {
        font-size: 26px;
        color: #8e8e8e;
      }
    }

    .hp-box-add.hp-box-add-half {
      width: 50%;
    }

    .hp-box-paste {
      background-color: #f3f5f7;
      border-radius: 0px;
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: 5px;
      text-align: center;
      line-height: 105px;
      display: none;

      i {
        font-size: 26px;
        color: #8e8e8e;
      }

      width: 50%;
      border-left: 0px solid #ffffff;
    }

    .hp-box-add:hover,
    .hp-box-paste:hover {
      i {
        color: #565656;
      }
    }
  }

  .hp-box:hover .hp-box-add,
  .hp-box:hover .hp-box-paste {
    display: flex;
  }

  .hp-box-article {
    position: absolute;
    overflow: hidden;
    top: 1px;
    bottom: 1px;
    background-color: #dbe2e6;
    border-left: 10px solid #c8ced2;
    padding: 10px;

    .article-info,
    .article-info h6 {
      color: #333;
      font-size: 13px;
      line-height: 14px;
      font-family: Arial;
      max-height: 40px;

      .hp-box-brand-logo {
        float: left;
        margin-top: -3px;
        margin-right: 5px;
      }

      h6 span {
        font-weight: 600;
        margin-bottom: 0px;
        max-height: 57px;
        overflow: hidden;
      }

      .time {
        margin-top: 3px;
        color: #333333;
      }
    }

    .icons {
      display: none;
      position: absolute;
      bottom: 5px;

      i {
        font-size: 10px;
      }
    }

    .fb-shared,
    .video-embed {
      float: right;
      margin-top: -5px;
    }

    .fb-shared {
      font-size: 140%;
      margin-top: -9px;
      margin-right: 3px;
    }
  }

  .hp-box-article.hp-box-active {
    border: 1px dotted #2c4762;
    opacity: 0.9;
  }

  .hp-box-article:hover {
    .icons {
      display: block;
    }
  }

  /* Hp box colors by site ********************** */

  .hp-box-plus1 {
    border-left: 10px solid #0077c0;
  }

  .hp-box-plus7 {
    border-left: 10px solid #0063ac;
  }

  .hp-box-emma {
    border-left: 10px solid #ad8b5a;
  }

  .hp-box-sarm {
    border-left: 10px solid #ed1c24;
  }

  .hp-box-polovnictvo, .hp-box-par, .hp-box-polovnictvo-rybarstvo, .hp-box-polovnictvo-a-rybarstvo {
    border-left: 10px solid #4b5f38;
  }

  .hp-box-sport24 {
    border-left: 10px solid #0079f1;
  }

  .hp-box-ncpz, .hp-box-novy-cas-pre-zeny {
    border-left: 10px solid #e73159;
  }

  .hp-box-autobild, .hp-box-autobild-slovensko {
    border-left: 10px solid #bf1a2f;
  }

  .hp-box-eva {
    border-left: 10px solid #000000;
  }

  .hp-box-adam {
    border-left: 10px solid #8ac650;
  }

  .hp-box-zivot {
    border-left: 10px solid #ed1d25;
  }

  .hp-box-trend, .hp-box-etrend {
    border-left: 10px solid #ac0904;
  }

  .hp-box-zdravie, .hp-box-izdravie {
    border-left: 10px solid #00a8e6;
  }

  .hp-box-lepsiebyvanie, .hp-box-lepsie-byvanie {
    border-left: 10px solid #990033;
  }

  .hp-box-dobrejedlo, .hp-box-dobre-jedlo {
    border-left: 10px solid #f2535b;
  }

  .hp-box-zahradkar, .hp-box-izahradkar {
    border-left: 10px solid #97bf00;
  }

  .hp-box-wanda {
    border-left: 10px solid #e94b98;
  }

  .hp-box-zena {
    border-left: 10px solid #dc0024;
  }

  .hp-box-magazin {
    border-left: 10px solid #f88f22;
  }

  .hp-box-pekne-byvanie, .hp-box-ipekne-byvanie {
    border-left: 10px solid #00bfc2;
  }

  .left-panel {
    width: 150px;
    border: 1px solid #e7e7e7;
    border-left: 0;
    border-top: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 115px;

    .ctr {
      bottom: -5px;
    }
  }

  .hp-time-status {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 2px;
    background-color: #f85d6b;
    z-index: 8;

    span {
      color: #fff;
      font-size: 10px;
      font-size: .625rem;
      font-weight: 400;
      line-height: 13px;
      background-color: #f85d6b;
      text-transform: uppercase;
      padding: 3px 5px;
      position: absolute;
      top: 0;
      letter-spacing: .2px;
    }
  }
}

.hp-arrows {
  i {
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  i:hover {
    color: #000;
  }
}

em.fa.fa-arrow-down {
  color: #fc4b6c;
}

em.fa.fa-dot-circle {
  color: #1693c1;
}

em.fa.fa-arrow-up {
  color: #41b883;
}

.ctr {
  position: relative;
  font-size: 85%;
}
</style>
