<template>
  <app-module-view>
    <template slot="body">
      <app-content-block-item-filter>
      </app-content-block-item-filter>
      <app-timeline
        v-for="contentBlock in contentBlocks"
        :key="contentBlock.id"
        :content-block="contentBlock"
      >
      </app-timeline>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import { mapState } from 'vuex'
import ContentBlockItemFilter from '@/components/contentBlockItem/ContentBlockItemFilterOld'
import Timeline from '@/components/contentBlockItem/timeline/TimelineOld'

export default {
  name: 'ContentBlockItemListOld',
  data () {
    return {
      timelineConfig: {
        positions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        times: []
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    contentBlocks () {
      const contentBlocks = this.$store.getters['contentBlock/list']
      return contentBlocks.filter(contentBlock => contentBlock.type === 'article')
    },
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    }
  },
  components: {
    appModuleView: ModuleView,
    appContentBlockItemFilter: ContentBlockItemFilter,
    appTimeline: Timeline
  },
  methods: {
    init () {
      this.getContentBlocks()
    },
    getContentBlocks () {
      this.$store.commit('contentBlock/setLimit', 50)
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    }
  },
  created () {
    this.init()
  }
}
</script>
